<script>
import Toggler from '@/components/dump/Toggler.vue'

export default {
  name: 'Message',
  components: { Toggler },
  props: ['text', 'withToggle', 'initialToggleState'],
  methods: {
    update(evt) {
      this.$emit('handle', evt)
    }
  }
}
</script>

<template>
<article class="message__article">
  <p class="message__text">{{ text }}</p>
  <Toggler v-if="withToggle" :initial="initialToggleState" @changeToggleState="update($event)"/>
</article>
</template>

<style scoped lang="sass">
.message
  &__article
    display: flex
    align-items: flex-start
    padding: rem(18) rem(15)
    text-align: left
    background: $bg-grey
    border-radius: $block-radius
  &__text
    margin: 0
</style>

<script>
export default {
  props: ['initial'],
  name: 'Toggler',
  data: () => ({
    state: false
  }),
  mounted() {
    this.state = this.initial
  },
  methods: {
    handle() {
      this.state = !this.state
      this.$emit('changeToggleState', this.state)
    }
  }
}
</script>

<template>
  <div class="toggler">
    <input type="checkbox" @input="handle()" id="toggler" :checked="state" />
    <label for="toggler" class="toggler__label"></label>
  </div>
</template>

<style scoped lang="sass">
.toggler
  min-height: rem(24)
  margin-left: rem(12)
  display: flex
  align-items: center
  input
    display: none
    pointer-events: none
    opacity: 0
    position: absolute
    &:checked
      + .toggler__label
        &:after
          transform: translate(calc(100% + 3px), -50%)
          left: unset
          background: $main-color
  &__label
    height: rem(30)
    width: rem(54)
    background: $white
    border-radius: rem(16)
    position: relative
    display: block
    cursor: pointer
    &:after
      content: ""
      background: $white
      height: rem(24)
      width: rem(24)
      display: block
      border-radius: 50%
      position: absolute
      top: 50%
      left: 0
      right: unset
      transition: .5s
      transform: translate(3px, -50%)
      box-shadow: 0 rem(3) rem(6) rgba(0, 0, 0, 0.16)
</style>

<script>
import Message from '@/components/dump/Message.vue'
import ConstructionScheme from '@/components/dump/ConstructionScheme.vue'

export default {
  name: 'AdditionalParams',
  components: { ConstructionScheme, Message },
  props: {
    sector: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    hasAdditionalFloorParams() {
      return this.sector.constructionType.isCombinedLocation
    }
  }
}
</script>

<template>
  <section class="additional-params">
    <Message
      :withToggle="true"
      :initialToggleState="hasAdditionalFloorParams"
      :text="this.$t('message.constructionTypes.message.addFloorParams')"
      @handle="$emit('update', $event)"/>
    <ConstructionScheme
      :url="''"
      :canShow="true"
      :canZoom="false"
      :classList="{
        'add-floor-params-scheme': true,
        'add-floor-params-scheme--checked': hasAdditionalFloorParams
      }"
    >
      <img src="@/assets/img/add-floor-params.svg" width="100%" alt="" />
    </ConstructionScheme>
    <slot v-if="hasAdditionalFloorParams"></slot>
  </section>
</template>

<style lang="sass">
.additional-params
  display: grid
  grid-auto-columns: 1fr 1fr
  +media((grid-template-areas: (0: 'message message' 'floor floor', 700: 'message floor' 'message floor', 768: 'message message' 'floor floor')))
  position: relative
  grid-gap: 0 rem(20)
  margin: rem(30) 0 0
  .message__article
    grid-area: message
  .add-floor-params-scheme
    +media((grid-area: (0: floor, 768: none)))
    +media((display: (0: none, 700: flex)))
    margin: 0
    +media((position: (0: static, 768: absolute)))
    bottom: 0
    +media((right: (0: rem(-30), 1170: rem(-60))))
    +media((transform: (0: translate(0, 0), 768: translate(100%, 0))))
    filter: grayscale(100%)
    opacity: 0.4
    &--checked
      opacity: 1
      filter: none
    .img-wrapper
      +media((height: (0: 100%, 768: rem(132), 1170: auto)))
      margin: 0
      border: none
      border-radius: 0
      img
        object-fit: fill
  &:has(.dropdown)
    +media((grid-template-areas: (0: 'message message' 'buildingType buildingType' 'location location', 700: 'message floor' 'buildingType buildingType' 'location location', 768: 'message message' 'buildingType buildingType' 'location location')))
    .message__article, .add-floor-params-scheme
      margin-bottom: rem(30)
    .add-floor-params-scheme
      top: 0
      bottom: auto
</style>
